<template>
  <div id="ChangePassword">
    <SDrawer :title="$t('resetPassword.header')" :visible.sync="visible">
      <el-form label-position="top" :model="changeFormLabelAlign" :rules="changingPasswordRules"
        ref="changeFormLabelAlign">
        <el-form-item :label="$t('common.field.accNum')">
          <span class="number" v-html="accountNumber"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.currPw')" prop="currentPassword" required class="currentPassword">
          <el-input type="password" v-model="changeFormLabelAlign.currentPassword" autocomplete="false"
            data-testid="currentPassword"></el-input>
        </el-form-item>
        <el-form-item type="password" :label="$t('common.field.newPw')" required prop="newPassword">
          <el-input type="password" v-model="changeFormLabelAlign.newPassword" autocomplete="false"
            data-testid="newPassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.field.confirmPw')" required prop="confirmNewPassword">
          <el-input type="password" v-model="changeFormLabelAlign.confirmNewPassword" autocomplete="false"
            data-testid="confirmNewPassword"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="btn-box">
          <el-button @click="disableChangePasswordVisible(false)"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }" data-testid="disableChangePasswordVisible">
            {{ $t('common.button.cancel') }}
          </el-button>
          <el-button @click="changeSubmitForm" :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="changeSubmitForm" type="primary">
            {{ $t('common.button.changePw') }}
          </el-button>
        </div>
      </template>
    </SDrawer>
    <SDialog :title="$t('resetPassword.header')" :visible.sync="pwdConfirmVisible" :showFooter="false">
      <StatusMessage :title="$t('common.field.operationSuccessful')">
        <template #content>
          <div class="home_status_content" v-html="$t('home.pw.confirm')"></div>
        </template>
      </StatusMessage>
    </SDialog>
  </div>
</template>

<script>
import { apiUpdate_mt4_account_password, apiCheck_current_password, apiCheck_new_password } from '@/resource'
import { rsa } from '@/util/encrypt'
import passwordValidator from 'password-validator'
export default {
  name: 'ChangePassword',
  props: {
    changePasswordVisible: Boolean,
    number: Number,
    isDemo: Boolean,
  },
  data() {
    const validateCurrentPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')))
      } else {
        this.checkPasswordExisting().then(result => {
          if (!result) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')))
          } else {
            this.changeFormLabelAlign.currentPasswordVerification = true
          }
        })
      }
    }
    const validateDuplicatedPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')))
      } else {
        if (!this.schema.validate(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')))
        } else {
          this.checkPasswordDuplication().then(result => {
            if (!result) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')))
            } else {
              this.changeFormLabelAlign.newPasswordVerification = true
              callback()
            }
          })
        }
      }
    }
    const validateConfirmNewPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.confirmNewPasswordVerfication = false
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.confirmPwReq')))
      } else if (value !== this.changeFormLabelAlign.newPassword) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')))
      } else {
        callback()
        this.changeFormLabelAlign.confirmNewPasswordVerfication = true
      }
    }
    return {
      changeFormLabelAlign: {
        userId: '',
        mtAccount: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        currentPasswordVerification: false,
        newPasswordVerification: false,
        confirmNewPasswordVerfication: false,
      },
      changingPasswordRules: {
        currentPassword: [{ validator: validateCurrentPassword, trigger: ['blur'] }],
        newPassword: [{ validator: validateDuplicatedPassword, trigger: ['blur', 'change'] }],
        confirmNewPassword: [{ validator: validateConfirmNewPassword, trigger: ['blur', 'change'] }],
      },
      schema: '',
      pwdConfirmVisible: false,
    }
  },
  mounted() {
    this.schema = new passwordValidator()
    this.schema
      .is()
      .min(8)
      .has()
      .uppercase()
      .has()
      .digits()
      .has()
      .not()
      .spaces()
  },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
    visible: {
      get: function () {
        return this.changePasswordVisible
      },
      set: function (visible) {
        this.disableChangePasswordVisible(visible)
      },
    },
    accountNumber() {
      return this.number
    },
  },
  methods: {
    changeSubmitForm() {
      if (
        this.changeFormLabelAlign.currentPasswordVerification &&
        this.changeFormLabelAlign.newPasswordVerification &&
        this.changeFormLabelAlign.confirmNewPasswordVerfication
      ) {
        apiUpdate_mt4_account_password({
          userId: this.uuid,
          mtAccount: this.number,
          currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
          newPassword: rsa(this.changeFormLabelAlign.newPassword),
          isDemo: this.isDemo,
        })
          .then(resp => {
            if (resp.data.code == 0) {
              this.changePasswordVisible = false
              this.pwdConfirmVisible = true
            }
          })
          .catch(err => {
            console.log(err)
            this.disableChangePasswordVisible(false)
          })
      }
    },
    disableChangePasswordVisible(bool) {
      this.$nextTick(() => {
        this.changeResetForm()
        this.$emit('update:changePasswordVisible', bool)
      })
    },
    changeResetForm() {
      this.$refs.changeFormLabelAlign.resetFields()
      this.$refs.changeFormLabelAlign.confirmNewPasswordVerfication = false
      this.$refs.changeFormLabelAlign.currentPasswordVerification = false
      this.$refs.changeFormLabelAlign.checkPasswordDuplication = false
    },
    checkPasswordExisting() {
      return apiCheck_current_password({
        userId: this.uuid,
        mtAccount: this.number,
        currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
        isDemo: this.isDemo,
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('the password exists')
            return Promise.resolve(true)
          } else {
            console.log("the password doesn't exist")
          }
        } else {
          console.log('Errors happened when verifing current password')
        }
        return Promise.resolve(false)
      })
    },
    checkPasswordDuplication() {
      return apiCheck_new_password({
        userId: this.uuid,
        mtAccount: this.number,
        newPassword: rsa(this.changeFormLabelAlign.newPassword),
        isDemo: this.isDemo,
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log("The new password doesn't exist")
            return Promise.resolve(true)
          } else {
            console.log('The new password duplicates')
          }
        } else {
          //wrong
          console.log('Errors happend when verifying new password')
        }
        return Promise.resolve(false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
