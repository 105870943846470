<template>
  <div id="CurrencyIcon" :style="{ backgroundColor: randomColor }">{{ currencyContent }}</div>
</template>

<script>
export default {
  name: 'CurrencyIcon',
  props: {
    currency: {
      type: [String, null, undefined],
      default: '--',
    },
  },
  computed: {
    currencyContent() {
      const curr = this.currency
      if (curr === 'RMB' || curr === 'rmb') {
        return '￥'
      } else if (curr === 'USD' || curr === 'usd') {
        return '$'
      } else if (curr === 'GBP' || curr === 'gbp') {
        return '￡'
      } else if (curr === 'EUR' || curr === 'eur') {
        return '€'
      } else if (curr === 'JPY' || curr === 'jpy') {
        return 'Ұ'
      }
      return this.currency
    },
    randomColor() {
      const colors = ['#CD2A2A', '#2BACA4', '#FE8900', '#1776E6', '#DFAE00', '#9A4CCA']
      return colors[Math.floor(Math.random() * colors.length)]
    },
  },
}
</script>

<style lang="scss" scoped>
#CurrencyIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: $white;
}
</style>
